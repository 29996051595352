<template>
    <div class="bgc-con">
        <div class="content">
            <div class="left-bar">
                <div class="item">
                    <div class="head">交易管理</div>
                    <p @click="toUrl('order')">我的订单</p>
                    <p @click="toUrl('aftersale')">售后管理</p>
                </div>
                <el-divider></el-divider>
                <div class="item">
                    <div class="head">我的资产</div>
                    <p @click="toUrl('coupon')">优惠券</p>
                </div>
                <el-divider></el-divider>
                <div class="item">
                    <div class="head">账号管理</div>
                    <p @click="toUrl('message')">我的消息</p>
                    <p @click="toUrl('collect')">我的收藏</p>
                    <p @click="toUrl('address')">收货地址</p>
                    <p @click="toUrl('info')">个人资料</p>
                    <p @click="toUrl('security')">账号安全</p>
                </div>
            </div>
            <div class="right-bar">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
<script>
import {useRouter} from 'vue-router'
export default {
    setup(){
        const router = useRouter()
        const toUrl = (path) =>{
            router.push('/profile/'+path)
        }
        return{
            toUrl
        }
    }
}
</script>
<style scoped>
    .bgc-con{
        width: 100%;
        min-height: 600px;
        background-color: #F8F8F8;
        margin-top: 35px;
        position: relative;
    }
    .content{
        width: 1200px;
        margin: 0px auto 0;
        padding-top: 30px;
        display: flex;
        padding-bottom: 60px;
    }
    .content .left-bar{
        width: 220px;
        background: #fff;
        min-height: 750px;
    }
    .content .left-bar .item{
        padding-top: 19px;
        padding-bottom: 13px;
    }
    .content .left-bar .item .head{
        font-size: 16px;
        color: #020202;
        font-weight: bold;
        padding-bottom: 10px;
        padding-left: 40px;
    }
    ::v-deep .el-divider--horizontal{
        margin: 0;
    }
    .content .left-bar .item p{
        font-size: 14px;
        color: #303133;
        padding: 8px 0;
        cursor: pointer;
        padding-left: 40px;
    }
    .content .right-bar{
        margin-left: 20px;
        flex: 1;
    }
</style>